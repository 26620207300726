import React from "react"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import './404.css'
import Button from "@material-ui/core/Button";
import {Link} from "gatsby";

const NotFoundPage = () => (
  <Layout id='NotFound'>
    <SEO title="404: Not found" />
    <div style={{marginTop: '100px', padding: '2em', textAlign: 'center', height: '60vh'}}>
        <div className='title-big'>404 NOT FOUND</div>
        <p>Die angeforderte Seite existiert leider nicht...</p>
        <Link to='/'>
            <Button variant="contained"  size="small" color="primary">
                Zur Startseite
            </Button>
        </Link>
    </div>
  </Layout>
);

export default NotFoundPage
